import axios from 'axios';
import { debugError } from "./errors";
import { BASE_URL } from "./config";

export const getInvoices = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/invoice`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
}


export const invoiceApprove = async ({ token, walletId, invoiceId }) => {
  console.log("invoiceApprove", { token, walletId, invoiceId })
  try {
    const response = await axios.patch(`${BASE_URL}/api/v1/invoice`, { wallet_id: walletId, invoice_id: invoiceId }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
}

export const invoiceCancel = async ({ token, invoiceId }) => {
  console.log("invoiceCancel", { token,  invoiceId })
  try {
    const headers = { 'Authorization': `Bearer ${token}` };
    const request = { method: 'delete', url: `${BASE_URL}/api/v1/invoice?id=${invoiceId}`, headers };
    const response = await axios(request);
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
}