import axios from 'axios';
import { dictionary, debugError, getErrorInfo } from "./errors";
import { BASE_URL } from "./config";

// API error_code dictionary
export const errors = dictionary;
export const getError = getErrorInfo;
export const wtfError = debugError;

export const signIn = async ({ username, password }) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/auth`, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

export const getUser = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

export const getWallets = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/me/wallets`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

/* Withdrawals */

export const tranferToWallet = async ({ token, from, to, amount }) => {
  console.log({ token, from, to, amount });

  try {
    const headers = { 'Authorization': `Bearer ${token}` };
    const agentByPhone = await axios.get(`${BASE_URL}/api/v1/client?phone=${to}`, { headers });
    const { id: payee } = agentByPhone.data;
    if (!payee) throw ({ error_code: 0, message: "Не удалось получить id кошелька получателя" });

    const transerBody = {
      payer_wallet: from,
      beneficiary_agent: payee,
      amount: amount * 100,
      metadata: {
        description: "Transfer from Walllet.app"
      }
    };


    const transfer = await axios({ method: 'post', url: `${BASE_URL}/api/v1/transaction`, headers, data: transerBody, })
    const { id: transferID, status: transferStatus } = transfer.data;
    if (!transferID) throw ({ error_code: 0, message: "Не удалось создать транзакцию на перевод" });
    if (!transferStatus === 400) throw ({ error_code: 0, message: "Не удалось создать транзакцию на перевод" });

    const approveBody = { id: transferID, approve: "APPROVED" };
    const approveTransfer = await axios({ method: 'patch', url: `${BASE_URL}/api/v1/transaction`, headers, data: approveBody });
    const { status: transactionStatus } = approveTransfer.data;
    if (transactionStatus !== 100) {
      throw ({ error_code: 0, message: "Не удалось подтвердить транзакцию" })
    }
    return approveTransfer.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

/* Регистрация пользователя */

// 1. Отправляем SMS на номер
export const Verify = async ({ phone }) => {
  try {
    const response = await axios.post(`${BASE_URL}/cmp/verify`, { phone_number: phone });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

// 2. Проверяем код (из SMS)
export const CheckCode = async ({ phoneNumber, smsCode }) => {
  try {
    const response = await axios.patch(`${BASE_URL}/cmp/verify`, { phone_number: phoneNumber, secret: smsCode });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

// 3. Завершаем регистрацию
export const registration = async ({ name, password, token }) => {
  console.log({ name, password, token });
  const headers = { 'Authorization': `Bearer ${token}` };
  const body = {
    full_name: name, username: name, password,
    email: "", uin: "", country: "",
    address: "", company: "", position: "",
    // системное легаси (спрашивать у Павла )
    owner_id: 1, currency: 1
  };
  const request = { method: 'post', url: `${BASE_URL}/cmp/register`, headers, data: body };
  try {
    const response = await axios(request);
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

export const fakeSignIn = (credentials) => {
  console.log("credentials", credentials)
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (credentials.username === "admin" && credentials.password === "password") {
        resolve({ token: "fake_token" });
      } else {
        reject({ error: "Invalid username or password" });
      }
    }, 2000);
  });
};

/* Восстановление пароля */

// 1. Отправляем SMS на номер
export const Verification = async ({ phone }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/verification`, { phone_number: phone });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

// 2. Проверяем код (из SMS)
export const CheckRepairCode = async ({ phoneNumber, smsCode }) => {
  try {
    const response = await axios.patch(`${BASE_URL}/api/v1/verification`, { phone_number: phoneNumber, secret: smsCode });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}

// 3. Меняем пароль
export const changePassword = async ({ password, token }) => {
  console.log({ password, token });
  const headers = { 'Authorization': `Bearer ${token}` };
  const body = { password };
  const request = { method: 'put', url: `${BASE_URL}/api/v1/password`, headers, data: body };
  try {
    const response = await axios(request);
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
}