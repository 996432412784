import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useSign } from '../SignContext';
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";
import { CheckRepairCode } from "api";
import { getErrorInfo } from "api/errors";

const CheckRepairCodePage = () => {
  const [smsCode, setSmsCode] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);

  const { isAuthenticated } = useAuth();
  const { phoneNumber, setRegToken } = useSign();

  const navigate = useNavigate();

  if (isAuthenticated) {
    return <Navigate to="/wallets" replace />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInProgress(true);
    try {
      const response = await CheckRepairCode({ phoneNumber, smsCode });
      // console.log("response", response);
      if (!response.token) setError(0)
      else {
        setRegToken(response.token);
        navigate('/repair/password');
      }
    } catch (error) {
      // console.log("error", error);
      setError(getErrorInfo(error.response?.data?.error_code || 0));
    }
    setInProgress(false);
  };

  return (
    <InnerPageLayout title="Восстановить пароль" backUrl="/repair">
      <div className={formStyles.wrapper}>
        <form className={formStyles.form} onSubmit={handleSubmit} disabled={inProgress}>
          <div className={formStyles.header}>
            <h1>Забыли пароль?</h1>
            <h2>Подтвердите номер телефона кодом из SMS</h2>
          </div>

          {error !== null && <Alert type="danger"
            title={error.title}
            message={error.message} />}

          <div className={formStyles.group}>
            <label htmlFor="login">Код из SMS</label>
            <input
              type="text"
              placeholder="159753"
              value={smsCode}
              onChange={(e) => setSmsCode(e.target.value)}
            />
          </div>

          <div className={formStyles.footer}>
            <button type="submit">Проверить код</button>
          </div>
        </form>
      </div>
    </InnerPageLayout>
  );
};

export default CheckRepairCodePage;