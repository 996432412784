import React, { useState, useEffect, useContext, createContext } from 'react';
import { getErrorInfo } from "api/errors";
import { getInvoices, invoiceApprove, invoiceCancel } from 'api/invoices';
import { useAuth } from 'AuthContext';
import { useUser } from 'UserContext';

const InvoiceContext = createContext();

const fetchInvoicesList = async (token, setInvoices, setFetchingInvoices) => {
    setFetchingInvoices(true);
    const invoicesData = await getInvoices(token);
    if (invoicesData) {
        const invoicesForPayment = invoicesData.filter(({ status, transaction }) => {
            if (status === 0) return true;
            if (status === 1) return false;
            return status === 2 && transaction.status !== 400 ? false : true;
        });

        const remapInvoice = invoice => {
            const { id, status, transaction } = invoice;
            let state = false;
            let error = null;

            if (status === 2 && transaction.status === 400) {
                state = "error";
                error = transaction.error;
            }
            
            console.log(id, state, error);
            return { ...invoice, state, error };
        }

        const invoicesWithState = invoicesForPayment.map(remapInvoice);
        setInvoices(invoicesWithState);
    }
    setFetchingInvoices(false);
};

const updateInvoiceState = (invoices, invoiceId, state) => {
    return invoices.map(invoice => (
        invoice.id === invoiceId ?
            { ...invoice, state }
            :
            invoice
    ));
};

const processInvoice = async (actionFn, token, invoiceId, setInvoices, setError, setProcessing, additionalArgs = {}) => {
    setError(null);
    setProcessing(true);
    try {
        setInvoices(prevInvoices => updateInvoiceState(prevInvoices, invoiceId, actionFn.name));
        const invoiceData = await actionFn({ token, invoiceId, ...additionalArgs });
        if (invoiceData) {
            console.log(`${actionFn.name} invoice`, invoiceData);
        }
        setInvoices(prevInvoices => updateInvoiceState(prevInvoices, invoiceId, "success"));
    } catch (error) {
        setError(error.message);
        setInvoices(prevInvoices => updateInvoiceState(prevInvoices, invoiceId, "error"));
    }
    setProcessing(false);
};


export const InvoiceProvider = ({ children }) => {
    const { token } = useAuth();
    const { wallets } = useUser();

    const [invoices, setInvoices] = useState(null);
    const [fetchingInvoices, setFetchingInvoices] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (invoices === null) fetchInvoicesList(token, setInvoices, setFetchingInvoices);
    }, [invoices]);

    const handleApprove = invoiceId => {
        if (!processing) {
            const walletId = wallets[0].id;
            processInvoice(invoiceApprove, token, invoiceId, setInvoices, setError, setProcessing, { walletId });
        }
    };

    const handleCancel = invoiceId => {
        if (!processing) {
            processInvoice(invoiceCancel, token, invoiceId, setInvoices, setError, setProcessing);
        }
    };

    return (
        <InvoiceContext.Provider value={{
            invoices, fetchingInvoices, error,
            handleCancel, handleApprove
        }}>
            {children}
        </InvoiceContext.Provider>
    );
};

export const useInvoice = () => useContext(InvoiceContext);