import React from 'react';
import styles from "./invoices.module.css";
import btnStyles from "components/Button/button.module.css";
import { formatAmount, formatDate } from "utils";
import { useInvoice } from "./InvoiceContext";
import Alert from "components/Alert/Alert";
import NoItemsIcon from './NoItemsIcon';
import { getErrorInfo } from 'api/errors';

const NoInvoices = () => {
  return (
    <div className={styles.noItems}>
      <NoItemsIcon className={styles.noItemsIcon} />
      <p>Cчетов на оплату нет</p>
    </div>
  );
}

const Invoices = () => {
  const { invoices, handleCancel, handleApprove } = useInvoice();
  return (
    <div className={styles.invoices}>
      {
        invoices && invoices.length > 0 && invoices.map(invoice => {
          const amount = formatAmount(invoice.amount);
          const date = formatDate(invoice.created_at);

          return <Invoice
            key={invoice.id}
            id={invoice.id}
            company={invoice.merchant.name}
            amount={amount}
            text={invoice.metadata?.text}
            date={date}
            state={invoice.state}
            error={invoice.error}
            status={invoice.status}
            onCancel={() => handleCancel(invoice.id)}
            onApprove={() => handleApprove(invoice.id)} />;
        })
      }

      {invoices?.length === 0 && <NoInvoices />}
    </div>
  )
}

const Invoice = ({ id, company, amount, text, date, status, state = false, error, onCancel, onApprove }) => {
  const showActions = state !== "success" && state !== "error";
  return (
    <div className={styles.invoice}>
      <div className={styles.section}>
        <div className={styles.company}>{company}</div>
        <div className={styles.amount}>{amount}</div>
      </div>
      <div>
        <div className={styles.date}>{`${date.day} в ${date.time}`}</div>
        {text && (<div className={styles.text}>
          Комментарий продавца
          <span>{text}</span>
        </div>)}
      </div>
      {showActions && (
        <div className={styles.actions}>
          <button className={`${btnStyles.button} ${btnStyles.outline}`} onClick={onCancel} disabled={state !== false}>
            {state === "cancel" ? "Отклоняем..." : "Отклонить"}
          </button>
          <button className={`${btnStyles.button}`} onClick={onApprove} disabled={state !== false}>
            {state === "approve" ? "Оплачиваем..." : "Оплатить"}
          </button>
        </div>
      )}
      { state === "success" && <Alert type="success" message="👍" />}
      { state === "error" && <Alert type="danger" title="Оплата не удалась" message={getErrorInfo(error).message} />}
    </div>
  );
};

export default Invoices;
