import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useSign } from '../SignContext';
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";
import { Verification } from "api";
import { getErrorInfo } from "api/errors";

const RepairPage = () => {
  const [phone, setPhone] = useState();
  const [inProgress, setInProgress] = useState(false);

  const { isAuthenticated } = useAuth();
  const { phoneNumber, setPhoneNumber } = useSign();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (phoneNumber) setPhone(phoneNumber);
  }, [phoneNumber]);

  if (isAuthenticated) {
    return <Navigate to="/wallets" replace />;
  }

  const handleSubmit = async (event) => {
    setInProgress(true);
    event.preventDefault();
    setPhoneNumber(phone);
    try {
      const response = await Verification({ phone });
      navigate('/repair/code');
    } catch (error) {
      setError(getErrorInfo(error.response?.data?.error_code || 0));
    }
    setInProgress(false);
  };

  return (
    <InnerPageLayout title="Восстановить пароль" backURL="/">
      <div className={formStyles.wrapper}>
        <form className={formStyles.form} onSubmit={handleSubmit} disabled={inProgress}>
          <div className={formStyles.header}>
            <h1>Забыли пароль?</h1>
            <h2>Подтвердите номер и создайте новый</h2>
          </div>

          {error !== null && <Alert type="danger"
            title={error.title}
            message={error.message} />}

          <div className={formStyles.group}>
            <label htmlFor="login">Телефон</label>
            <input
              type="text"
              placeholder="Без +7 и пробелов. Только цифры"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className={formStyles.footer}>
            <button type="submit">Отправить SMS</button>
          </div>
          <div className={formStyles.footer}>
            <div><span>Вспомнили пароль?</span> <Link to="/signin">Войдите</Link></div>
            <div><span>Впервые здесь?</span> <Link to="/signup">Создайте новый кошелек</Link></div>
          </div>
        </form>
      </div>
    </InnerPageLayout>
  );
};

export default RepairPage;