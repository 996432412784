import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useSign } from '../SignContext';
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";
import { registration } from "api";
import { getErrorInfo } from 'api/errors';

const RegistrationPage = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);

  const { isAuthenticated } = useAuth();
  const { regToken } = useSign();
  const navigate = useNavigate();

  if (isAuthenticated) {
    return <Navigate to="/wallets" replace />;
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setInProgress(true);
    // console.log("name", name, "password", password);
    try {
      const response = await registration({ name, password, token: regToken });
      setPassword("");
      navigate('/signin');
    } catch (error) {
      // console.log("error", error);
      setError(getErrorInfo(error.response?.data?.error_code || 0));
    }
    setInProgress(false);
  };

  return (
    <InnerPageLayout title="Создать кошелек" backURL="/signup">
      <div className={formStyles.wrapper}>
        <form className={formStyles.form} onSubmit={onSubmit} disabled={inProgress}>
          <div className={formStyles.header}>
            <h1>Зарегистрируйтесь</h1>
            <h2>Последний шаг. Заполните данные о себе и пароль</h2>
          </div>

          {error !== null && <Alert type="danger"
            title={error.title}
            message={error.message} />}

          <div className={formStyles.group}>
            <label htmlFor="name">Ваше имя</label>
            <input
              type="text"
              id="name"
              placeholder="Ex: Ivan Petrov"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={formStyles.group}>
            <label htmlFor="name">Пароль</label>
            <input
              type="text"
              id="password"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className={formStyles.footer}>
            <button type="submit">Создать кошелек</button>
          </div>
          <div className={formStyles.footer}>
            <div><span>У вас уже есть кошелек?</span> <Link to="/signin">Войдите</Link></div>
          </div>
        </form>
      </div>
    </InnerPageLayout>
  );
};

export default RegistrationPage;