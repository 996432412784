import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import { InvoiceProvider } from "./InvoiceContext";
import Invoices from './Invoices';


const InvoicesPage = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/wallets" replace />;
  }

  return (
    <InnerPageLayout title="Счета на оплату" backURL="/wallets">
      <InvoiceProvider>
        <Invoices />
      </InvoiceProvider>
    </InnerPageLayout>
  );
};

export default InvoicesPage;